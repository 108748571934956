import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import Toasted from 'vue-toasted'

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css'

import AOS from 'aos'
import 'aos/dist/aos.css'

import Icon from 'vue-awesome/components/Icon.vue'
import 'vue-awesome/icons/brands/linkedin'
import 'vue-awesome/icons/brands/youtube'
import 'vue-awesome/icons/desktop'
import 'vue-awesome/icons/envelope'
import 'vue-awesome/icons/arrow-left'
import 'vue-awesome/icons/arrow-right'
import 'vue-awesome/icons/star'
import 'vue-awesome/icons/bars'
import 'vue-awesome/icons/pen-alt'
import 'vue-awesome/icons/plus'
import 'vue-awesome/icons/sign-in-alt'
import 'vue-awesome/icons/users'
import 'vue-awesome/icons/play'
import 'vue-awesome/icons/user-shield'
import 'vue-awesome/icons/calendar'
import 'vue-awesome/icons/chart-line'
import 'vue-awesome/icons/times'
import 'vue-awesome/icons/graduation-cap'
import 'vue-awesome/icons/book'
import 'vue-awesome/icons/map-signs'
import 'vue-awesome/icons/building'
import 'vue-awesome/icons/image'
import 'vue-awesome/icons/video'
import 'vue-awesome/icons/file-pdf'
import 'vue-awesome/icons/check-square'
import 'vue-awesome/icons/align-left'
import 'vue-awesome/icons/question'
import 'vue-awesome/icons/heart'
import 'vue-awesome/icons/table'
import 'vue-awesome/icons/comments'
import 'vue-awesome/icons/link'
import 'vue-awesome/icons/bell'
import 'vue-awesome/icons/project-diagram'

import Home from './Home.vue'

Vue.use(BootstrapVue)

Vue.use(Toasted, {
    position: 'top-center',
    theme: 'outline',
    duration: 4000,
    className: 'toaster-error',
})

Vue.component('v-icon', Icon)

Vue.config.productionTip = false

Vue.prototype.$mailTo = (email, subject = null) => {
    window.location.href = `mailto:${email}${(subject) ? `?subject=${encodeURI(subject)}` : ''}`
    setTimeout(() => {
        if (document.hasFocus()) {
            Vue.toasted.info(
                `It appears your browser has no configured email client. You can email us at: ${email}`,
                {
                    duration: 12000,
                    action: {
                        text: 'Copy address',
                        onClick: (_, toastObject) => {
                            navigator.clipboard.writeText(email)
                                .then(() => {
                                    toastObject.goAway(0)
                                    setTimeout(() => Vue.toasted.info('Copied to clipboard!'), 500)
                                })
                        },
                    },
                },
            )
        }
    }, 500)
}

new Vue({
    created () {
        AOS.init({
            once: true,
        })
    },
    render: h => h(Home),
}).$mount('#app')

<template>
    <section id="hero">
        <b-row class="mb-5">
            <b-col lg="6" class="mb-5 pt-5">
                <h1>The progressive portfolio platform.</h1>
                <!-- <h3 class="text-white">Progressive portfolios for education.</h3> -->
                <b-button
                    href="#intro"
                    class="purple-button mt-4"
                    pill
                >
                    <v-icon name="arrow-right"/>
                    Learn more
                </b-button>
                <b-button
                    class="red-button mt-4 ml-3 d-md-none"
                    v-b-modal.intro-video-modal
                    pill
                >
                    <v-icon name="brands/youtube"/>
                    Watch the video
                </b-button>
            </b-col>
            <b-col sm="0" md="8" offset-md="2" lg="6" offset-lg="0" class="mb-5 pt-5 d-none d-md-block">
                <div
                    class="video-content-wrapper theme-shadow"
                    @click="showVideo = true"
                >
                    <b-button
                        class="dark-blue-button"
                        pill
                    >
                        <v-icon name="brands/youtube"/>
                        Watch the video
                    </b-button>
                    <iframe
                        v-if="showVideo"
                        src="https://www.youtube-nocookie.com/embed/06xKPwQuMfk?rel=0&autoplay=1"
                        title="About eJournal"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        autoplay
                        width="1920"
                        height="1080"
                    />
                </div>
            </b-col>
        </b-row>
        <b-modal
            id="intro-video-modal"
            hideFooter
            hideHeader
            size="lg"
        >
            <iframe
                src="https://www.youtube.com/embed/06xKPwQuMfk?rel=0&autoplay=1"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                autoplay
            />

        </b-modal>
    </section>
</template>

<script>
export default {
    data () {
        return {
            showVideo: false,
        }
    },
}
</script>

<style lang='sass'>
@import '../sass/modules/colors.sass'
@import '../sass/partials/shadows.sass'
@import '../sass/modules/breakpoints.sass'

#hero
    color: white
    padding-bottom: 0px
    @include md-max
        text-align: center
        h1
            margin: auto
    .row
        margin-top: 20px
        @include md
            min-height: calc(60vh - 100px)
        @include lg-max
            padding-top: 20px
    .video-content-wrapper
        position: relative
        /* 16:9 */
        padding-bottom: 56.25%
        border-radius: 20px
        overflow: hidden
        height: 0
        background-image: url('../assets/images/intro-video-placeholder.png')
        background-size: cover
        .btn
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
        iframe
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%

#intro-video-modal
    .modal-content
        border-radius: 22px
    .modal-body
        padding: 0px
        position: relative
        width: 100%
        /* 16:9 */
        padding-bottom: 56.25%
        border-radius: 20px
        overflow: hidden
        height: 0
        iframe
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
</style>

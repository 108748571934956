<template>
    <section id="use-cases">
        <b-row>
            <b-col>
                <h3 class="text-light-grey">
                    Supervise with ease. Scaffold progress. Encourage reflective learning.
                </h3>
                <h2>
                    <!-- Made for student success. -->
                    Teachers <v-icon name="heart"/> using eJournal for...
                </h2>
                <transition name="fade" mode="out-in">
                    <div
                        v-if="!selectedUseCase"
                        key="SelectUseCase"
                    >
                        <!-- @click="selectedUseCase = 'portfolio'" -->
                        <div
                            class="use-case"
                            key="select-portfolio"
                        >
                            <v-icon
                                name="book"
                                class="text-blue"
                                scale="3"
                            />
                            <h6>
                                Assessment & development portfolios.
                            </h6>
                            <!-- <span class="mt-2 text-blue d-block">
                                <v-icon
                                    name="arrow-right"
                                    class="mr-2"
                                />
                                Read more
                            </span> -->
                        </div>
                        <!-- @click="selectedUseCase = 'graduationProject'" -->
                        <div
                            class="use-case"
                            key="select-graduation-project"
                        >
                            <v-icon
                                name="graduation-cap"
                                class="text-green"
                                scale="2"
                            />
                            <v-icon
                                name="building"
                                class="text-yellow internship-icon"
                                scale="2"
                            />
                            <h6>
                                Graduation projects & internships.
                            </h6>
                            <!-- <span class="mt-2 text-blue d-block">
                                <v-icon
                                    name="arrow-right"
                                    class="mr-2"
                                />
                                Read more
                            </span> -->
                        </div>
                        <!-- @click="selectedUseCase = 'studyProgress'" -->
                        <div
                            class="use-case"
                            key="select-study-progress"
                        >
                            <v-icon
                                name="map-signs"
                                class="text-pink"
                                scale="3"
                            />
                            <h6>
                                Study progress & career orientation.
                            </h6>
                            <!-- <span class="mt-2 text-blue d-block">
                                <v-icon
                                    name="arrow-right"
                                    class="mr-2"
                                />
                                Read more
                            </span> -->
                        </div>
                    </div>
                    <div
                        v-else-if="selectedUseCase === 'portfolio'"
                        key="portfolio-use-case"
                        class="mt-2 text-left position-relative"
                    >
                        <h4 class="d-inline mb-0">
                            <span class="use-case-symbol">
                                <v-icon
                                    name="book"
                                    class="text-blue"
                                    scale="1.5"
                                />
                            </span>
                            Asses
                        <!-- @click="selectedUseCase = null"sment & development portfolios. -->
                        </h4>
                        <b-button
                            class="back-button dark-blue-button"
                            pill
                        >
                            <v-icon name="times"/>
                        </b-button>
                        <b-card
                            class="text-black"
                        >
                            <p class="mt-3 mb-0">

                            </p>
                        </b-card>
                    </div>
                    <div
                        v-else-if="selectedUseCase === 'graduationProject'"
                        key="graduation-project-use-case"
                        class="mt-2 text-left position-relative"
                    >
                        <h4 class="d-inline mb-0">
                            <span class="use-case-symbol">
                                <v-icon
                                    name="graduation-cap"
                                    class="text-green"
                                    scale="1.5"
                                />
                            </span>
                            <span class="use-case-symbol">
                                <v-icon
                                    name="building"
                                    class="text-yellow"
                                    scale="1.5"
                                />
                            </span>
                            Gradu
                        <!-- @click="selectedUseCase = null"ation projects & internships. -->
                        </h4>
                        <b-button
                            class="back-button dark-blue-button"
                            pill
                        >
                            <v-icon name="times"/>
                        </b-button>
                        <b-card
                            class="text-black"
                        >
                            <p class="mt-3 mb-0">

                            </p>
                        </b-card>
                    </div>
                    <div
                        v-else-if="selectedUseCase === 'studyProgress'"
                        key="study-progress-use-case"
                        class="mt-2 text-left position-relative"
                    >
                        <h4 class="d-inline mb-0">
                            <span class="use-case-symbol">
                                <v-icon
                                    name="map-signs"
                                    class="text-pink"
                                    scale="1.5"
                                />
                            </span>
                            Study
                        <!-- @click="selectedUseCase = null" progress & career orientation. -->
                        </h4>
                        <b-button
                            class="back-button dark-blue-button"
                            pill
                        >
                            <v-icon name="times"/>
                        </b-button>
                        <b-card
                            class="text-black"
                        >
                            <p class="mt-3 mb-0">

                            </p>
                        </b-card>
                    </div>
                </transition>
            </b-col>
        </b-row>
    </section>
</template>

<script>
export default {
    data () {
        return {
            selectedUseCase: null,
        }
    },
}
</script>

<style lang="sass">
@import '../sass/modules/colors.sass'
@import '../sass/modules/breakpoints.sass'
@import '../sass/partials/shadows.sass'

#use-cases
    background: linear-gradient(to right, rgba(37, 44, 57, 0.9), rgba(37, 44, 57, 0.9)), url('../assets/images/lecture-hall.jpg')
    background-size: cover
    color: white
    text-align: center
    h3, h2
        margin-left: auto
        margin-right: auto
    h2 svg
        color: $theme-red
        height: 0.8em
        width: auto
    .use-case
        @extend .theme-shadow
        max-width: 320px
        display: inline-block
        position: relative
        margin: 20px 20px 0px 0px
        padding: 20px
        padding-left: 80px
        vertical-align: top
        color: black
        text-align: left
        border-radius: 20px
        background-color: white
        // cursor: pointer
        &:hover span
            color: darken($theme-blue, 10%)
        span svg
            margin-top:  -3px
        & > svg:first-child
            position: absolute
            vertical-align: middle
            left: 20px
            top: 20px
        h6
            display: inline
            vertical-align: middle
        .internship-icon
            position: absolute
            left: 40px
            top: 40px
    .card
        @extend .theme-shadow
        margin-top: 20px
        border-radius: 20px
    .use-case-symbol, .back-button
        width: 40px
        height: 40px
        svg
            position: absolute
            left: 50%
            top: 50%
            transform: translate(-50%, -50%)
            margin: 0px
    .use-case-symbol
        display: inline-block
        border-radius: 50px
        padding: 20px
        background-color: white
        position: relative
        margin-right: 10px
        vertical-align: middle
    .back-button
        @extend .theme-shadow
        position: absolute
        top: 0px
        right: 0px
</style>

<template>
    <section
        id="intro"
    >
        <img src="../assets/images/wave.svg" id="wave"/>
        <div class="content-wrapper sticky">
            <b-row class="mb-5 no-select" noGutters>
                <b-col class="lms-view-wrapper" xs="12" xl="9" data-aos="zoom-in-up">
                    <div class="lms-view">
                        <transition-group name="fade">
                            <img
                                v-show="selectedLMS === 'canvas' && eJournalEnabled"
                                src="../assets/images/canvas-ejournal-view.png"
                                key="canvas-view-eJournal-enabled"
                            />
                            <img
                                v-show="selectedLMS === 'canvas' && !eJournalEnabled"
                                src="../assets/images/canvas-standard-view.png"
                                key="canvas-view-eJournal-disabled"
                            />
                            <img
                                v-show="selectedLMS === 'brightspace' && eJournalEnabled"
                                src="../assets/images/brightspace-ejournal-view.png"
                                key="brightspace-view-eJournal-enabled"
                            />
                            <img
                                v-show="selectedLMS === 'brightspace' && !eJournalEnabled"
                                key="brightspace-view-eJournal-disabled"
                                src="../assets/images/brightspace-standard-view.png"
                            />
                            <img
                                v-show="selectedLMS === 'blackboard' && eJournalEnabled"
                                src="../assets/images/blackboard-ejournal-view.png"
                                key="blackboard-view-eJournal-enabled"
                            />
                            <img
                                v-show="selectedLMS === 'blackboard' && !eJournalEnabled"
                                key="blackboard-view-eJournal-disabled"
                                src="../assets/images/blackboard-standard-view.png"
                            />
                            <img
                                v-show="selectedLMS === 'moodle' && eJournalEnabled"
                                src="../assets/images/moodle-ejournal-view.png"
                                key="moodle-view-eJournal-enabled"
                            />
                            <img
                                v-show="selectedLMS === 'moodle' && !eJournalEnabled"
                                key="moodle-view-eJournal-disabled"
                                src="../assets/images/moodle-standard-view.png"
                            />
                        </transition-group>
                    </div>
                    <div class="enable-ejournal-toggle-wrapper">
                        <div class="enable-ejournal-toggle">
                            <img
                                src="../assets/images/ejournal-logo-white.svg"
                                class="mb-1"
                            />
                            <b-form-checkbox
                                v-model="eJournalEnabled"
                                name="check-button"
                                class="d-inline-block align-middle"
                                size="lg"
                            />
                            <b-badge
                                v-if="eJournalEnabled"
                                class="background-light-green text-green align-middle"
                            >
                                Enabled
                            </b-badge>
                            <b-badge
                                v-else
                                class="background-light-red text-red align-middle"
                            >
                                Disabled
                            </b-badge>
                        </div>
                        <img
                            src="../assets/images/arrow-up-drawing.svg"
                            class="arrow"
                        />
                        Click to see the difference!
                    </div>
                </b-col>
                <b-col class="lms-buttons" xs="12" xl="3">
                    <div
                        class="canvas"
                        :class="{ 'active': selectedLMS === 'canvas'}"
                        @click="selectedLMS = 'canvas'; resetLMSChanger()"
                    >
                        <img src="../assets/images/canvas.png"/>
                    </div>
                    <div
                        class="brightspace"
                        :class="{ 'active': selectedLMS === 'brightspace'}"
                        @click="selectedLMS = 'brightspace'; resetLMSChanger()"
                    >
                        <img src="../assets/images/brightspace.png"/>
                    </div>
                    <div
                        class="blackboard"
                        :class="{ 'active': selectedLMS === 'blackboard'}"
                        @click="selectedLMS = 'blackboard'; resetLMSChanger()"
                    >
                        <img src="../assets/images/blackboard.png"/>
                    </div>
                    <div
                        class="moodle"
                        :class="{ 'active': selectedLMS === 'moodle'}"
                        @click="selectedLMS = 'moodle'; resetLMSChanger()"
                    >
                        <img src="../assets/images/moodle.png"/>
                    </div>
                    <span><v-icon name="plus"/> Your LMS</span>
                </b-col>
            </b-row>
            <b-row class="mb-5">
                <b-col>
                    <h3>
                        Make learning visible.
                    </h3>
                    <h2 class="d-block">
                        Integrated tool suite for progressive portfolios.
                    </h2>
                    <p>
                        eJournal is a platform for <b>development and assessment portfolios</b>, seamlessly integrated with learning environments such as Canvas, Blackboard and Brightspace.
                        With eJournal, students document their personal development in <b>interactive collections of evidence and 360-degree feedback</b>.
                    </p>
                    <p>
                        Whether it concerns one unit of study or the bigger picture of a study programme: eJournal makes it possible to monitor, guide and assess progress effectively.
                    </p>
                </b-col>
            </b-row>
            <b-row class="align-items-start">
                <b-col md="6">
                    <h5 class="mb-3">Before eJournal</h5>
                    <ul class="red-minus-list">
                        <li>Students lack insight in their progress.</li>
                        <li>Mostly summative assessment.</li>
                        <li>Limited self-reflection.</li>
                        <li>Hard for teachers to scaffold structure.</li>
                    </ul>
                </b-col>
                <b-col md="6">
                    <h5 class="mb-3">With eJournal</h5>
                    <ul class="green-plus-list">
                        <li>Students are aware of their complete learning trajectory.</li>
                        <li>Formative assessment and feedback.</li>
                        <li>Encourage reflective learning.</li>
                        <li>Custom templates and goals help students focus on content.</li>
                    </ul>
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>
export default {
    name: 'LMSSection',
    data () {
        return {
            selectedLMS: 'canvas',
            eJournalEnabled: true,
            lmsChanger: setInterval(this.nextLMS, 5000),
        }
    },
    methods: {
        nextLMS () {
            if (this.selectedLMS === 'canvas') {
                this.selectedLMS = 'brightspace'
            } else if (this.selectedLMS === 'brightspace') {
                this.selectedLMS = 'blackboard'
            } else if (this.selectedLMS === 'blackboard') {
                this.selectedLMS = 'moodle'
            } else if (this.selectedLMS === 'moodle') {
                this.selectedLMS = 'canvas'
            }
        },
        resetLMSChanger () {
            clearInterval(this.lmsChanger)
            this.lmsChanger = setInterval(this.nextLMS, 5000)
        },
    },
}
</script>


<style lang='sass'>
@import '../sass/modules/breakpoints.sass'
@import '../sass/modules/colors.sass'
@import '../sass/partials/shadows.sass'

#intro
    position: relative
    margin-top: 250px
    padding-top: 0px
    padding-left: 0px
    padding-right: 0px
    background-color: white
    #wave
        width: 100%
        height: 150px
        margin-top: -150px
        min-height: 150px
    .content-wrapper
        padding: 0px 20px
        margin-top: -220px
        position: sticky
        bottom: 0px
        @include lg
            padding: 0px 80px
    .lms-view-wrapper
        position: relative
        max-width: 700px
        margin: 20px auto
        width: 100%
        .enable-ejournal-toggle-wrapper
            position: absolute
            z-index: 2
            right: -40px
            bottom: 80px
            font-family: 'Caveat'
            width: 172px
            font-size: 1.3em
            text-align: center
            .arrow
                height: 1em
            .enable-ejournal-toggle
                @extend .theme-shadow, .hover
                text-align: left
                background: linear-gradient(to bottom right, $theme-dark-blue, black)
                padding: 20px
                // Fix for switch
                font-size: 0em
                border-radius: 20px
                .badge
                    font-size: 0.8rem
                    font-family: 'Roboto'
                    display: inline-block
                @include md-max
                    right: 20px
                    bottom: 20px
                @include sm-max
                    width: auto
                    border-radius: 0px 0px 20px 20px
                    margin-bottom: 5px
                    img
                        width: 125px
                        float: right
                        margin-top: -5px
            @include sm-max
                position: relative
                width: auto
                text-align: left
                top: 0px
                left: 0px
                bottom: 0px
                .arrow
                    margin-left: 30px
        .lms-view
            @extend .theme-shadow
            position: relative
            width: 100%
            /* 16:9 */
            padding-bottom: 56.25%
            border-radius: 20px
            overflow: hidden
            height: 0
            @include sm-max
                border-radius: 20px 20px 0px 0px
            img
                border-radius: 18px
                z-index: 1
                position: absolute
                width: 100%
                height: 100%
                transition: all 1s cubic-bezier(.25,.8,.25,1) !important
                @include sm-max
                    border-radius: 18px 18px 0px 0px
    .lms-buttons
        background: white
        padding: 10px
        border-radius: 20px
        max-width: 1200px
        margin: 20px auto
        text-align: center
        div
            position: relative
            padding: 10px 20px
            background-color: white
            border-radius: 10px
            height: 45px
            line-height: 20px
            border-width: 2px
            border-style: solid
            border-color: transparent
            transition: all .3s cubic-bezier(.25,.8,.25,1) !important
            margin: 10px
            @include lg-max
                display: inline-block
            @include sm-max
                padding: 10px
                height: 40px
                line-height: 15px
                margin: 0px 5px 5px 5px
            img, svg
                height: 20px
                @include sm-max
                    height: 15px
            svg
                height: 20px
                margin-right: 5px
                vertical-align: center
            &.active
                border-color: $theme-dark-grey
            &:not(.active):hover
                cursor: pointer
                border-color: $theme-medium-grey
        span
            color: $theme-medium-grey
            margin: 10px
            padding: 10px 20px
            white-space: nowrap
            border-radius: 20px
    b
        color: $theme-blue
</style>

<template>
    <div id='home'>
        <div class="blob-container">
            <!-- This SVG is from https://codepen.io/Ali_Farooq_/pen/gKOJqx -->
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 310 350" class="blob">
                <path d="M156.4,339.5c31.8-2.5,59.4-26.8,80.2-48.5c28.3-29.5,40.5-47,56.1-85.1c14-34.3,20.7-75.6,2.3-111
                c-18.1-34.8-55.7-58-90.4-72.3c-11.7-4.8-24.1-8.8-36.8-11.5l-0.9-0.9l-0.6,0.6c-27.7-5.8-56.6-6-82.4,3c-38.8,13.6-64,
                48.8-66.8,90.3c-3,43.9,17.8,88.3,33.7,128.8c5.3,13.5,10.4,27.1,14.9,40.9C77.5,309.9,111,343,156.4,339.5z"/>
            </svg>
        </div>
        <header-section/>
        <hero-section/>
        <intro-section/>
        <use-case-section/>
        <feature-section/>
        <!-- ADD TESTIMONIAL SECTION -->
        <!-- <testimonial-section/> -->
        <schedule-demo-section/>
        <custom-footer/>
    </div>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue'
import HeroSection from '@/components/HeroSection.vue'
import IntroSection from '@/components/IntroSection.vue'
// import TestimonialSection from '@/components/TestimonialSection.vue'
import FeatureSection from '@/components/FeatureSection.vue'
import UseCaseSection from '@/components/UseCaseSection.vue'
import ScheduleDemoSection from '@/components/ScheduleDemoSection.vue'
import CustomFooter from '@/components/Footer.vue'

export default {
    name: 'Home',
    components: {
        HeaderSection,
        HeroSection,
        IntroSection,
        // TestimonialSection,
        FeatureSection,
        UseCaseSection,
        ScheduleDemoSection,
        CustomFooter,
    },
    created () {
        const uri = window.location.href.split('?')
        if (uri.length === 2) {
            const vars = uri[1].split('&')
            vars.forEach((v) => {
                const tmp = v.split('=')
                if (tmp.length === 2 && tmp[0] === 'meetingConfirmed' && tmp[1]) {
                    this.$toasted.success('Meeting scheduled! Check your mailbox for a confirmation.')
                }
            })
        }
    },
}
</script>

<style lang='sass'>
@import 'sass/global.sass'

#home
    width: 100vw
    .blob-container
        overflow-x: hidden
        overflow-y: hidden
        width: 100vw
        height: 100vh
        position: fixed
        left: 0px
        top: 0px
        fill: black
        opacity: 0.5
        z-index: -1
        .blob
            width: 50vmin
            animation: move 40s ease-in-out infinite
            transform-origin: 50% 50%
    @keyframes move
        0%
            transform: scale(1) translate(10px, -30px)
        38%
            transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg)
        40%
            transform: scale(0.8, 1) translate(80vw, 30vh) rotate(160deg)
        78%
            transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg)
        80%
            transform: scale(1.3) translate(0vw, 50vh) rotate(-20deg)
        100%
            transform: scale(1) translate(10px, -30px)
</style>
